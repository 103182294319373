import React from "react"
import Img from 'gatsby-image';

import {
  Container,
  PropertyTypeContainer,
  TextContainer,
} from "./PropertyType.styles"
import ExploreMore from "../GlobalComponents/ExploreMore"




const Experts = ({ inverted, Icon, image, title , description, label, link, arabic }) => {

  return (
    <Container>
      <div className="container" style={{'direction':(arabic) ? 'rtl' : 'ltr'}}>
        <PropertyTypeContainer inverted={inverted}>
        {/* <Img
            fixed={data.localImage.childImageSharp.fixed}
            alt="property-type"
            placeholder="tracedSVG"
            className="property-img"
            layout="fullWidth"
          />       */}
          <img src={image} alt={description} style={{'max-width':'600px'}}></img>
          <TextContainer className={arabic==false ? '':'text-end'}>
            <img src={Icon} alt={title} width="62px" />
            <h3 className={arabic==false ? '':'text-end'}>{title}</h3>
            <p>{description}</p>
            <div className="link-container">
              <ExploreMore text={label} link={link} />
            </div>
          </TextContainer>
        </PropertyTypeContainer>
      </div>
    </Container>
  )
}

export default Experts
