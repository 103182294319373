import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import Experts from "../components/Experts"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"
import exportIcon from "../assets/icons/agent.svg";
import expertImage1 from "../assets/images/broker1.webp";
import expertImage2 from "../assets/images/broker2.webp";
import expertImage3 from "../assets/images/broker3.webp";

const Broker = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Broker" />
          <Hero
            arabic={language}
            title={
              language == false
                ? "Broker"
                : "وسطاء"
            }
            subTitle={
              language == false
                ? "Connecting Opportunities: Navigating Success in Markets Worldwide"
                : "ربط الفرص: التنقل في النجاح في الأسواق في جميع أنحاء العالم"
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

      
              <Experts
                inverted={false}
                Icon={exportIcon}
                image={expertImage1}
                title={
                  language == false
                    ? "El-Mir Real Estate"
                    : "المير العقارية"
                }
                description={
                  language == false
                    ? "we provide a brokerage experience that stands out for its commitment to client success, market expertise, and personalized service. Our team of dedicated brokers serves as your gateway to global markets, offering tailored advice and strategies to meet your investment goals, whether you're a seasoned investor or just starting out."
                    : "نحن نقدم تجربة وساطة تتميز بالتزامها بنجاح العميل وخبرة السوق والخدمة الشخصية. يعمل فريقنا من الوسطاء المتخصصين بمثابة بوابتك إلى الأسواق العالمية، حيث يقدمون نصائح واستراتيجيات مخصصة لتحقيق أهدافك الاستثمارية، سواء كنت مستثمرًا متمرسًا أو بدأت للتو."  
                  }
                  label={
                    language == false
                    ? "Call Our Broker"
                    : "اتصل بوسيطنا"
                  }
                link={"tel:+9613948739"}
                arabic={language}
              />


          <Experts
                inverted={true}
                Icon={exportIcon}
                image={expertImage2}
                title={
                  language == false
                    ? "Meiss Real Estate"
                    : "ميس للعقارات"
                }
                description={
                  language == false
                    ? "Your goals are our priority. OPD's brokers are committed to forging strong, lasting partnerships with our clients, guiding them to financial success through expert advice and personalized service."                  
                    : "أهدافك هي أولويتنا. يلتزم وسطاء OPD بإقامة شراكات قوية ودائمة مع عملائنا، وتوجيههم نحو النجاح المالي من خلال مشورة الخبراء والخدمة الشخصية."
                  }
                  label={
                    language == false
                    ? "Call Our Broker"
                    : "اتصل بوسيطنا"
                  }
                link={"tel:+9613948739"}
                arabic={language}
              />


          <Experts
                inverted={false}
                Icon={exportIcon}
                image={expertImage3}
                title={
                  language == false
                    ? "Mirna Hallal"
                    : "ميرنا حلال"
                }
                description={
                  language == false
                  ? "In the intricate dance of financial markets, the role of a broker is indispensable, acting as both navigator and ally in your investment journey. At OPD, we specialize in bridging the gap between your financial aspirations and the opportunities that await in the global markets. Our team of seasoned brokers is dedicated to offering strategic guidance, informed by deep market insights and a commitment to your financial well-being."
                  : "في الرقصة المعقدة للأسواق المالية، لا غنى عن دور الوسيط، حيث يعمل كمستكشف وحليف في رحلتك الاستثمارية. في OPD، نحن متخصصون في سد الفجوة بين تطلعاتك المالية والفرص التي تنتظرك في الأسواق العالمية. فريقنا من الوسطاء المتمرسين مكرس لتقديم التوجيه الاستراتيجي، المستنير برؤى السوق العميقة والالتزام برفاهيتك المالية."    
                }
                label={
                  language == false
                  ? "Call Our Broker"
                  : "اتصل بوسيطنا"
                }
                link={"tel:+9613948739"}
                arabic={language}
              />
          <br/><br/><br/>


        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default Broker
